import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import 'ses';
import * as Babel from '@babel/standalone';
import Chart from 'chart.js';
import fetch from 'node-fetch';

const DynamicComponentRenderer = ({ componentCode, props }) => {
  // 1. Create a custom require function to map module specifiers to trusted libraries.
  function customRequire(moduleName) {
    switch (moduleName) {
      case "react":
        return React; // use the globally loaded React
      case "react-dom":
        return ReactDOM;
      case "chart.js":
        return Chart;
      case "node-fetch":
        return fetch;
      default:
        throw new Error("Module not found: " + moduleName);
    }
  }

  useEffect(() => {
    // 2. Set up endowments for the SES compartment.
    // We need to supply a module object so that Babel's CommonJS transformation works.
    const moduleObj = { exports: {} };
    const endowments = {
      React,
      ReactDOM,
      document,
      console,
      // Expose our custom require function.
      require: customRequire,
      // Provide module and exports objects.
      module: moduleObj,
      exports: moduleObj.exports,
    };

    // Create a new SES compartment with the defined endowments.
    const compartment = new Compartment(endowments);

    // 3. Transpile the user code.
    // We use Babel to convert JSX and transform ES modules into CommonJS.
    const transpiledCode = Babel.transform(componentCode, {
      presets: [
        ["env", { modules: "commonjs" }], // convert import/export to CommonJS
        "react"                           // convert JSX
      ]
    }).code;

    // 4. Evaluate the transpiled code within the SES compartment.
    try {
      // Append a sourceURL for easier debugging.
      compartment.evaluate(transpiledCode + "\n//# sourceURL=userModule.js");
    } catch (error) {
      console.error("Error evaluating user code in SES compartment:", error);
    }

    // 5. Retrieve the default export from the module object.
    const DynamicComponent = moduleObj.exports.default;

    // 6. Render the component if it is valid.
    if (typeof DynamicComponent === "function") {
      createRoot(document.getElementById("dynamic-component-root")).render(
        React.createElement(DynamicComponent, props)
      );
    } else {
      document.getElementById("dynamic-component-root").innerText = "Error: No valid component exported.";
    }

  }, [componentCode]);
};

export default DynamicComponentRenderer;
