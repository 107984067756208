import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const additionalCheckboxRequired = this.data.get('additional-checkbox-required');
    const challengeRulesCheckbox = document.getElementById('challenge-rules-checkbox');
    const additionalChallengeRulesCheckbox = document.getElementById('challenge-rules-additional-checkbox');

    let challengeRulesAccepted = false;
    let additionalChallengeRulesAccepted = false;

    if (challengeRulesCheckbox) {
      challengeRulesCheckbox.addEventListener('change', (event) => {
        if (event.currentTarget.checked) {
          challengeRulesAccepted = true;
        } else {
          challengeRulesAccepted = false;
        }
  
        this.updateChallengeRulesButton(challengeRulesAccepted, additionalChallengeRulesAccepted);
      });
    }

    if (additionalChallengeRulesCheckbox && additionalCheckboxRequired === 'true') {
      additionalChallengeRulesCheckbox.addEventListener('change', (event) => {
        if (event.currentTarget.checked) {
          additionalChallengeRulesAccepted = true;
        } else {
          additionalChallengeRulesAccepted = false;
        }

        this.updateChallengeRulesButton(challengeRulesAccepted, additionalChallengeRulesAccepted);
      });
    }
  }

  updateChallengeRulesButton(challengeRulesAccepted, additionalChallengeRulesAccepted) {
    const additionalCheckboxRequired = this.data.get('additional-checkbox-required');
    const additionalChallengeRulesCheckbox = document.getElementById('challenge-rules-additional-checkbox');
    const acceptChallengeRulesButton = document.getElementById('accept-challenge-rules-button');

    if (additionalChallengeRulesCheckbox && additionalCheckboxRequired === 'true') {
      if (challengeRulesAccepted && additionalChallengeRulesAccepted) {
        acceptChallengeRulesButton.disabled = false;
      } else {
        acceptChallengeRulesButton.disabled = true;
      }
    } else {
      if (challengeRulesAccepted) {
        acceptChallengeRulesButton.disabled = false;
      } else {
        acceptChallengeRulesButton.disabled = true;
      }
    }
  }
}
